@import "theme";

html, body {
  background-image: linear-gradient(to right bottom, #4C566A, #2E3440);
  overflow-x: hidden;
  font-family: $font-primary;
  min-height: 100vh;
}

.switch-flex {
  & div:not(.switch) {
    width: 12em;
  }
  display: flex;
}

.switch {
  position: relative;
}
.switch .material-icons {
  position: absolute;
  top: .25em;
}

.spaced {
  margin-top: 1em;
  margin-bottom: 1em;
}

h2 {
  small {
    font-size: 1rem;
  }
}

.d-none {
  display: none !important;
}
