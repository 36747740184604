@import "theme";


h1, h2, h3, h4, h5, h6 {
	color: $theme-primary;
}

h1 {
	font-size: 2.9em;
}

h2 {
	font-size: 2.6em;
}

h3 {
	font-size: 2.2em;
}

h4 {
	font-size: 1.8em;
}

h5 {
	font-size: 1.6em;
}

h6 {
}

a {
	color: $theme-primary-light !important;

	&:hover {
		color: $theme-primary-light !important;
	}
}

.collection {
	background-color: $theme-black;
	border: none;

	.collection-header {
		color: $theme-white;
		background-color: $theme-black;
		border-bottom: 4px solid $theme-grey;
	}

	.collection-item {
		background-color: $theme-black;
		border-bottom: 2px solid $theme-grey;

		&:last-of-type {
			border-bottom: none;
		}
	}
}

.select-wrapper {
	.caret {
		path:first-of-type {
			fill: $theme-white;
		}
	}
}

.dropdown-content {
	li {
		background-color: $theme-grey !important;

		span {
			color: $theme-white !important;
		}

		&:hover {
			background-color: $theme-primary-light;

			span {
				color: $theme-grey;
			}
		}

		.highlight {
			color: $theme-primary !important;
		}
	}

	li.selected, li:active, li:focus {
		background-color: $theme-primary !important;

		&:hover {
			background-color: $theme-primary-light;

			span {
				color: $theme-grey !important;
			}
		}
	}
}

.chips {
	.chip {
		background-color: $theme-primary;
		color: $theme-white;
		cursor: pointer;
		user-select: none;
	}
}

.chips .input:not(:disabled) {
	//border-bottom: 1px solid #9e9e9e;
	//box-shadow: 0 1px 0 0 transparent !important;
}

.chips input {
	color: $theme-white !important;
}

.chips.focus {
	border-bottom: 1px solid $theme-primary !important;
	box-shadow: 0 1px 0 0 $theme-primary !important;
}

label {
	color: $theme-primary;
}

.chips + label {
	color: $theme-primary !important;
}

ul.pagination li {
	&.active {
		background-color: $theme-primary-light;

		button, a {
			font-weight: bold;
			color: $theme-grey !important;
		}
	}


	button, a {
		&:hover {
			background-color: $theme-primary;
		}

		font-weight: bold;
	}
}

input::placeholder {
	color: $theme-grey;
}

.btn {
	background-color: $nord10;
	min-width: 8em !important;

	&:hover {
		background-color: lighten($nord10, 10%);
	}
	&:focus {
		background-color: $nord10;
	}
}

.loader{
	position: absolute;
	display: inline-block;
	margin-top: -0.5em;
	margin-left: 2em;
	.spinner-layer {
		border-color: $theme-accent;
	}
}

input {
	&[type="submit"] {
		background-color: $theme-primary;

		&:hover {
			background-color: $theme-primary-light;
			color: $theme-grey;
		}
	}

	color: $theme-white;
}


.input-label {
	font-weight: bold;
	color: $theme-primary;
}

input[type="checkbox"] + span::after {
	border-color: $theme-primary;
	border-radius: 0;
	//border-width: 3px;
	//background-color: $theme-green !important;
}

input[type="checkbox"].filled-in:checked + span:not(.lever):after {
	background-color: $theme-primary;
	color: $theme-primary;
	border-color: transparent;
}

input[type="checkbox"].filled-in:checked + span:not(.lever):before {
	border-color: transparent;
}

input[type="checkbox"]:checked + span.lever {
	background-color: lighten($theme-primary, 20%) !important;
	border-color: transparent;
}

input[type="checkbox"]:checked + span.lever:after {
	background-color: $theme-primary !important;
	border-color: transparent;
}

input[type="checkbox"]:checked + span.lever:before {
	background-color: $theme-primary !important;
	border-color: transparent;
}

input[type="checkbox"] + span,
input[type="checkbox"],
input[type="radio"] + span,
input[type="radio"] {
	&:focus,
	&:active,
	&::selection {
		background-color: $theme-primary;
	}
}

.input-checkbox {
	font-weight: bold;
	color: $theme-primary;

	input {
		border-color: $theme-primary;
	}

	[type="radio"]:not(:checked) + span:before {
		border-radius: 0;
		border-color: $theme-grey !important;
		background-color: $theme-grey !important;
	}

	[type="radio"]:checked + span:before {
		border-radius: 0;
		border-color: $theme-grey !important;
		background-color: $theme-grey !important;
	}

	[type="radio"]:checked + span:after {
		border-color: $theme-grey !important;
		background-color: $theme-primary !important;
	}

	[type="checkbox"] + span:after {
		border-radius: 0;
		border-color: $theme-primary !important;
		//background-color: $theme-grey !important;
		//color: $theme-grey !important;
	}
}

.input-field {
	input[type="text"],
	input[type="text"]:disabled,
	input[type="password"],
	input[type="email"],
	textarea {
		box-sizing: border-box;
		padding-left: 0.5em;
		margin-top: 0.5em;
		color: white;
		border-bottom: 1px solid $theme-primary;
		//background-color: $theme-grey;
		&::placeholder {
			color: lighten($theme-grey, 20%);
		}
	}


	input[type="text"]:disabled {
		color: $theme-grey;
	}

	/* label color */
	input[type="text"]:disabled + label {
		color: $theme-grey;
	}

	label {
		//font-weight: bold;
		color: $theme-primary;
	}

	/* label focus color */
	input[type="text"]:focus + label,
	input[type="text"]:focus:disabled + label,
	input[type="password"]:focus + label,
	input[type="email"]:focus + label,
	textarea:focus + label {
		color: $theme-primary !important;
	}

	/* label underline focus color */
	input[type="text"]:focus,
	input[type="text"]:focus:disabled,
	input[type="password"]:focus,
	input[type="email"]:focus,
	textarea:focus {
		border-bottom: 1px solid $theme-primary !important;
		box-shadow: 0 1px 0 0 $theme-primary !important;
	}

	/* valid color */
	input[type="text"].valid,
	input[type="text"].valid:disabled,
	input[type="password"].valid,
	input[type="email"].valid,
	textarea.valid {
		//border-bottom: 1px solid #000;
		//box-shadow: 0 1px 0 0 #000;
	}

	/* invalid color */
	input[type="text"].invalid,
	input[type="text"].invalid:disabled,
	input[type="password"].invalid,
	input[type="email"].invalid,
	textarea.invalid {
		//border-bottom: 1px solid #000;
		//box-shadow: 0 1px 0 0 #000;
	}

	/* icon prefix focus color */
	.prefix.active {
		color: $theme-primary;
	}
}

textarea {
	color: $theme-white;
}

.card {
	background-color: $theme-grey;

	.card-image {
		text-align: center;
	}

	.card-content {
		color: $theme-white;
	}
}

.modal {
	background-color: $theme-grey;
	color: $theme-white;

	.modal-footer, .modal-content {
		background-color: $theme-grey;
	}
}

